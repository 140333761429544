<template>
  <step-lesson-layout :title="$t('module1.part5.lesson.title')" :title-annotation="$t('module1.part5.lesson.titleAnnotation')">
    <template v-slot:content>
      <!-- BLOCK1 -->
      <div class="lesson-level-block niveaux-block">
        <div v-for="niveau in ceeNiveaux"
          :key="niveau"
          class="level"
          :class="{ [niveau.name]: true }"
        >
          <div class="head">
            <img :src="niveau.image" :alt="niveau.name">
            <div class="head-content">
              <p class="label">{{ niveau.label }}</p>
              <p class="title">{{ niveau.title }}</p>
            </div>
          </div>
          <div class="paragraphe main-content" v-html="niveau.content"></div>
          <div class="bottom">
            <div class="thumb-bubble">
              <div class="bubble-content">
                <p class="title">{{ $t('module1.part5.lesson.bubbleTitle') }}</p>
                <p class="content paragraphe">{{ niveau.bubbleContent }}</p>
              </div>
              <img :src="niveau.thumbIcon" :alt="$t('global.imageAlts.thumbIcon')">
            </div>
            <div class="training-warning">
              <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')"/>
              <p class="paragraphe" v-html="niveau.warning"></p>
            </div>
          </div>
        </div>
      </div>

      <!-- BLOCK2 -->
      <div class="text-blocks">
        <div class="app-text-block">
          <p class="title">{{ $t('module1.part5.lesson.block2.title') }}</p>
          <p class="paragraphe" v-html="$t('module1.part5.lesson.block2.content')"></p>
        </div>
        <div class="app-text-block">
          <p class="title">{{ $t('module1.part5.lesson.block3.title') }}</p>
          <p class="paragraphe" v-html="$t('module1.part5.lesson.block3.content')"></p>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.moduleFinished') }}
        </p>
        <a :href="opinionFormUrl" target="_blank" rel="noopener" class="link-button-wrapper">
          <app-button-layout>
            {{ $t('global.navigation.giveOpinion') }}
          </app-button-layout>
        </a>
        <app-button-layout color="alternate-secondary" @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import GLOBAL_URLS from '@/constants/GLOBAL_URLS'

export default {
  name: 'Module1Part5Lesson',
  components: { StepLessonLayout, AppButtonLayout },
  setup () {
    const { goToNextStep, resumeLater } = useTrainingUtils()
    const opinionFormUrl = GLOBAL_URLS.OPINION_FORMS_URLS.MODULE_1
    return { goToNextStep, resumeLater, opinionFormUrl }
  },
  data () {
    return {
      ceeNiveaux: [
        {
          name: 'level-base',
          label: this.$t('module1.part5.lesson.blockNiveau1.label'),
          title: this.$t('module1.part5.lesson.blockNiveau1.title'),
          content: this.$t('module1.part5.lesson.blockNiveau1.content'),
          bubbleContent: this.$t('module1.part5.lesson.blockNiveau1.bubbleContent'),
          warning: this.$t('module1.part5.lesson.blockNiveau1.warning'),
          image: require('@/assets/module1/part5/niveau-cee-base.svg'),
          thumbIcon: require('@/assets/module1/part5/thumbs-up-base.svg')
        },
        {
          name: 'level-intermediaire',
          label: this.$t('module1.part5.lesson.blockNiveau2.label'),
          title: this.$t('module1.part5.lesson.blockNiveau2.title'),
          content: this.$t('module1.part5.lesson.blockNiveau2.content'),
          bubbleContent: this.$t('module1.part5.lesson.blockNiveau2.bubbleContent'),
          warning: this.$t('module1.part5.lesson.blockNiveau2.warning'),
          image: require('@/assets/module1/part5/niveau-cee-intermediaire.svg'),
          thumbIcon: require('@/assets/module1/part5/thumbs-up-intermediaire.svg')
        },
        {
          name: 'level-max',
          label: this.$t('module1.part5.lesson.blockNiveau3.label'),
          title: this.$t('module1.part5.lesson.blockNiveau3.title'),
          content: this.$t('module1.part5.lesson.blockNiveau3.content'),
          bubbleContent: this.$t('module1.part5.lesson.blockNiveau3.bubbleContent'),
          warning: this.$t('module1.part5.lesson.blockNiveau3.warning'),
          image: require('@/assets/module1/part5/niveau-cee-max.svg'),
          thumbIcon: require('@/assets/module1/part5/thumbs-up-max.svg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.niveaux-block {
  .level {
    .main-content {
      margin-top: $space-m;
    }
    .thumb-bubble {
      padding: $space-s $space-s $space-s $space-m;
      background-color: rgba($c-primary, 0.11);
      display: flex;
      margin: $space-m 0;
      border-radius: $radius;
      img {
        flex-shrink: 0;
        width: 50px;
      }
      .bubble-content {
        margin-right: $space-s;
        .title {
          color: $c-primary;
          font-size: $fz-xs;
          font-weight: $fw-l;
          text-transform: uppercase;
        }
        .content {
          margin-top: $space-xs;
        }
      }
    }
    .training-warning {
      flex-direction: column;
      img {
        margin-bottom: $space-xs;
      }
    }
  }
}
.text-blocks {
  padding: $space-s;
}
.end-block {
  margin: 0 auto;
}
@media (min-width: $bp-tablet) {
  .niveaux-block {
    .level {
      .bottom {
        margin-top: $space-m;
        display: grid;
        gap: $space-m;
        grid-template-columns: repeat(2, 1fr);
        .thumb-bubble {
          margin-top: 0;
        }
      }
    }
  }
  .text-blocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-m;
    padding: 0;
  }
}
</style>
